/*************************** Custom Style *************************/
.slider-handle {
    background-color: #5bc0de !important;
    background-image: -webkit-linear-gradient(top, #5bc0de 0%, #46b8da 100%) !important;
    background-image: -o-linear-gradient(top, #5bc0de 0%, #46b8da 100%) !important;
    background-image: linear-gradient(to bottom, #5bc0de 0%, #46b8da 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff46b8da', GradientType=0) !important;
}

/* === For phones =================================== */
@media (max-width: 767px) {

    .video-data-label {
        font-size: 100% !important;
        margin-right: 3px;
    }

    .video-select-buttongroup {
        padding-top: 3px;
    }
}

/* === For tablets ================================== */
@media (min-width: 768px) and (max-width:991px) {
    .video-data-buttongroup {
        padding-top: 10px;
    }

    .video-data-label {
        font-size: 100% !important;
        margin-right: 5px;
    }

    .video-select-buttongroup {
        padding-top: 3px;
    }
}

/* === For desktops ================================== */
@media (min-width: 992px) {
    .video-data-buttongroup {
        text-align: right;
    }

    .video-data-label {
        font-size: 100% !important;
        margin-left: 5px;
    }
}

.video-select-padding {
    padding-top: .7rem;
    padding-bottom: .6rem;
}
.video-control-button {
    min-width: 50px;
}

.video-control-icon {
    font-size: 14px;
    vertical-align: middle;
}

#buttonPlayPause>span {
    min-width: 29px;
}

#buttonSliderSteps {
    width: 70px;
}

.player-video-data {
    margin-top: 5px;
}

.slider-video-time {
    margin: 5px 0 5px 5px;
}

.sliderVideoTime {
    width: 99% !important;
}

.video-data-Badge {
    margin-right: 8px;
    font-size: 18px;
}

.video-data-buttongroup {
    padding: 8px;
}

.video-control-btn {
    font-size: 18px;
}

.center-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}